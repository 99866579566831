<template>
    <div class="" style="display: flex; flex-direction: column; align-items: center; padding: 10px; background: white; margin: 5px; ">
        <div style="color: black">
            <label style="margin-bottom: 3px;">Username: {{username}} </label>
            <label style="margin-bottom: 3px;">Wallet: {{account}} </label>
        </div>
        <br>
        <div style="color: black">
            <label style="margin-bottom: 3px; margin-right: 5px;">Your Oni Coin Balance: {{ oniCoinBalance }} </label>
            <button style="margin: 10px" @click="getOniCoinBalance(account)">Update Balance</button> <br>
        </div>
        <button style="margin: 10px" @click="getCurrentGameDay()">GET CURRENT GAME DAY</button> <br>
        <button style="margin: 10px" @click="getCurrentDayInSec()">GET Day in Sec</button> <br>
        <button style="margin: 10px" @click="getCurrentGameDayUnix()">GET CURRENT GAME DAY UNIX</button> <br>
        <button style="margin: 10px" @click="getLastBlockTimeStamp()">GET last Block Timestamp</button> <br>
        <button style="margin: 10px" @click="getFirstPart()">GET First Part</button> <br>
        <button style="margin: 10px" @click="getCheckCondition()">GET Check Condition</button> <br>
        <div style="color: black"> Current Game Day Int: {{currentGameDay}} </div>
        <div style="color: black"> Current Day in Sec: {{dayInSec}} </div>
        <div style="color: black"> Last Block Timestam: {{lastBlockTimestamp}} </div>
        <div style="color: black"> Current Game Day Unix: {{currentGameDayUnix}} </div>
        <div style="color: black"> First Part: {{firstPart}} </div>
        <div style="color: black"> Check Condition: {{checkCondition}} </div>
        <button style="margin: 10px" @click="getTimesHauntedNft(nftAddress, nftID)">Get How many times I was haunted</button> <br>
        <div style="color: black"> Times I was haunted: {{timesHaunted}} </div>
    </div>
    <div class="background-card" style="display: flex; align-items: center; padding: 5px; ">
        <button style="margin: 10px" @click="doDailyCheckIn(nftAddress, nftID)">
            Daily Check-In
        </button>
        <button style="margin: 10px" @click="getLastTimeDailyCheckIn(nftAddress, nftID)">
            get Last Time Daily check In
        </button>
        <div style="color: black"> Last Time Daily Check In was Done: {{lastDailyCheckIn}} </div>
        <p style="border: 5px solid blue; padding: 5px;"> <strong style="color: red; font-weight: 900;">Explanation: </strong> This is the first action the user/NFT holder needs to perform so that he can play the game.</p>
    </div>
    <div class="background-card" style="display: flex; align-items: center; padding: 5px; ">
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Nft Contract Address </label>
            <input type="text" v-model="haunteeContractAddressGeneric">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> NFT ID TO BE HAUNTED </label>
            <input type="text" v-model="haunteeIDGeneric">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Message </label>
            <input type="text" v-model="hauntMessage">
        </div>
        <button style="margin: 10px; padding: 5px;" 
            @click="hauntGenericOni(nftAddress, nftID, haunteeContractAddressGeneric, haunteeIDGeneric, currentGameDay, hauntMessage)">
            Haunt Generic Oni
        </button>
        <p style="border: 5px solid blue; padding: 5px;"> <strong style="color: red; font-weight: 900;">Explanation: </strong> On the game you can haunt other NFTs. The generic haunt enable the player to haunt any other NFT. The parameters are the NFT contract address on L1 and the ID of that nft.  <strong style="color: green; font-weight: 900;">Example: </strong> Oni Address: 0x0ee7bf57733e3da8c015a0e7e395d641fac512d6 and Oni ID: 6 .</p>
    </div>
    <div class="background-card" style="display: flex; align-items: center; padding: 5px; ">
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Nft Contract Address </label>
            <input type="text" v-model="haunteeContractHauntedSpecific">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> NFT ID TO BE HAUNTED </label>
            <input type="text" v-model="haunteeIDHauntedSpecific">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Message </label>
            <input type="text" v-model="hauntMessage">
        </div>
        <button style="margin: 10px" 
            @click="hauntSpecificNFT(nftAddress, nftID, haunteeContractHauntedSpecific, haunteeIDHauntedSpecific, currentGameDay, hauntMessage)">
            Haunt Specific Oni
        </button>
        <p style="border: 5px solid blue; padding: 5px;"> <strong style="color: red; font-weight: 900;">Explanation: </strong> On the game you can haunt other NFTs. The specific haunt enable the player to haunt some NFTs for that game event day. This IDs are gonna be those with certain traits. For example, we might be looking for an Oni with a basket ball, so we would give Jonathan's Oni id.<strong style="color: green; font-weight: 900;">Example: </strong> Oni Address: 0x0ee7bf57733e3da8c015a0e7e395d641fac512d6 and Oni ID: 2 .</p>
    </div>
    <div class="background-card" style="display: flex; align-items: center; padding: 5px; ">
        <button style="margin: 10px" 
            @click="claimDailyBonus(nftAddress, nftID)">
            Claim Daily Bonus
        </button>
        <p style="border: 5px solid blue; padding: 5px;"> <strong style="color: red; font-weight: 900;">Explanation: </strong> Once you have done the 1)Daily Check in, 2) Generic Haunting and, 3) Specific Haunting, you can clain the daily bonus.</p>
        <button style="margin: 10px" 
            @click="claimWeeklyBonus(nftAddress, nftID)">
            Claim Weekly Bonus
        </button>
        <p style="border: 5px solid blue; padding: 5px;"> <strong style="color: red; font-weight: 900;">Explanation: </strong> Once you have claimed 7 daiky bonus in a row without forgeting any missions during that 7 days, you can claim the weekly bonus.</p>
    </div>
    <div class="background-card" style="display: flex; align-items: center; padding: 5px; ">
        <button style="margin: 10px" 
            @click="getAllStoreAvailableItems()">
            Get random Store Items.
        </button>
        <br>
        <div style="color: black"> Available Store Items:</div>
            <div v-for="item in availableStoreItems">
                <div style="display: flex; flex-direction: column; border: 5px solid red; align-items: center; padding: 2px; margin-left: 2px;">
                    <div> ID: {{ item.id }} </div> 
                    <div> Price: {{item.price}} </div>
                    <div> Slot: {{item.slotType}}</div>
                </div>
            </div>
    </div>
    <div class="background-card" style="display: flex; align-items: center; padding: 5px; ">
        <button style="margin: 10px" 
            @click="getHouseAttrs(nftAddress, nftID)">
            Get my house attributes
        </button>
        <div style="color: black"> House attr IDS in inventory: {{ houseAttIdsInInventory }} </div>
    </div>
    <div class="background-card" style="display: flex; align-items: center; ">
        <button style="margin: 10px" @click="approveToStore()">Approve to Store</button> <br>
        <div style="color: black"> Is address approved to Store: </div>
        <p style="border: 5px solid blue; padding: 5px;"> <strong style="color: red; font-weight: 900;">Explanation: </strong> On the Oni Mansion Game we are using ERC20 tokens to play the game, A.K.A,  <span style="font-weight: 900">Oni Coins</span>. Since this is managed by a smart contract, only the owner would be able to transfer tokens. But we wanna buy items on the store and for that to work we need to enable the game to transfer coins on our behaf, that's what the approve to store does.</p>
    </div>
    <div class="background-card" style="display: flex; align-items: center; padding: 5px; ">
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Slot Position </label>
            <input type="text" v-model="slotPositionToBuyAttr">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> House Attr Id </label>
            <input type="text" v-model="houseAttrIdToBuyAttr">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Ammount </label>
            <input type="text" v-model="ammountToBuyAttr">
        </div>
        <button style="margin: 10px" 
            @click="buyHouseAttrs(nftAddress, nftID, slotPositionToBuyAttr, houseAttrIdToBuyAttr, ammountToBuyAttr)">
            Buy Attr
        </button>
        <p style="border: 5px solid blue; padding: 5px;"> <strong style="color: red; font-weight: 900;">Explanation: </strong> Our house have 9 positions ans you can see in the last button on this page. The slot is a number starting from 1-9 that represent the position where you will equip the item after the purchase.<strong style="color: green; font-weight: 900;">Example: </strong> slot: 1, attr ID: 20 and ammount 5. (remember the the input needts to match the information on the store)</p>
    </div>
    <div class="background-card" style="display: flex; align-items: center; padding: 5px; ">
        <button style="margin: 10px" 
            @click="openLootBox(nftAddress, nftID)">
            Open Loot Box
        </button>
        <p style="border: 5px solid blue; padding: 5px;"> <strong style="color: red; font-weight: 900;">Explanation: </strong> Every 15 missions you can open a loot box starting on mission counter 14, then on 29, then on 44 and finally on 59. Each loot box will have a specific position in your  "loot box inventory", the first lootbox will be on position 0, second position 1, third position 2 and forth position 3.</p>
        <button style="margin: 10px" 
            @click="missionCounter(nftAddress, nftID)">
            Get My Mission Counter
        </button>
        <div style="color: black"> Mission counter: {{missionCounterResolved}} </div>
        <button style="margin: 10px" 
            @click="buyLootBoxItem(nftAddress, nftID)">
            buyLootBox
        </button>
    </div>
    <div class="background-card" style="display: flex; align-items: center; padding: 5px; ">
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> ATTR ID </label>
            <input type="text" v-model="attrIDEquipAttr">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Slot Position </label>
            <input type="text" v-model="slopPositionIDEquipAttr">
        </div>
        <button style="margin: 10px" 
            @click="equipAttrToHouse(nftAddress, nftID, attrIDEquipAttr, slopPositionIDEquipAttr)">
            Equip Item
        </button>
        <p style="border: 5px solid blue; padding: 5px;"> <strong style="color: red; font-weight: 900;">Explanation: </strong> The equipped item will override the previous equiped item on the position.<strong style="color: green; font-weight: 900;">Example: </strong> Equipping Attr ID 8 on position 2, where previously there was an equiped item on poisiton 2 will imply that now the item equiped on position 2 is Attr ID 8 and no longer the previous Attr ID. </p>
        
    </div>
    <div class="background-card" style="padding: 30px; height: auto">
        Messages Received:
        <div v-for="item in messages" :key="item.id">
            <span v-if="nftID === item.hauntee_id">Message: {{item.message}}</span><br>
            <span >Sender: {{item.haunter_id}}</span>
        </div>
    </div>
    <div class="background-card" style="padding: 30px; height: auto">
        Messages That The NFT Owner Received:
        <div v-for="item in messages" :key="item.id">
            <span >Haunted BY: {{item.haunter_id}}</span>
        </div>
    </div>
    <div class="background-card" style="display: flex; align-items: center; padding: 5px; ">
        <button style="margin: 10px" 
            @click="updateHouseEquipedItems(nftAddress, nftID)">
            update My House Equiped Items
        </button>
        <div style="display: flex; flex-direction: column; padding 5px;">
            <div style="color: black"> Side Window: {{sideWindow}} </div>
            <div style="color: black"> Roof Window: {{roofWindow}} </div>
            <div style="color: black"> First Front Window: {{firstFrontWindow}} </div>
            <div style="color: black"> Second Front Window: {{secondFrontWindow}} </div>
            <div style="color: black"> Tower Window: {{towerWindow}} </div>
            <div style="color: black"> Chimney: {{chimney}} </div>
            <div style="color: black"> Door: {{door}} </div>
            <div style="color: black"> Roof: {{roof}} </div>
            <div style="color: black"> Yard: {{yard}} </div>
        </div>
        <p style="border: 5px solid blue; padding: 5px; margin-left: 20px;"> <strong style="color: red; font-weight: 900;">Explanation: </strong> Here shows which item is equiped in each position. </p>
    </div>
    
</template>
<script src="./TrialGame.js"></script>
