/* eslint-disable no-unused-vars */
import axios from 'axios';
import { Web3GameService } from '../../services/Web3GameService';
export default {
  name: 'TrialGame',
  props: {
    msg: String,
  },
  data: () => ({
    game: null,
    nftID: '',
    nftAddress: '',
    walletToName: {
      '0xFF43eD599B3c80fB71bb67c9D5452765e63d7a43': 'Bruce',
      '0xad7595fb1b3dF0468522106312F9c968152059A7': 'Nicholas',
      '0xf5C9d16C74269DcD8281C91490fC69564372a55F': 'Jonathan',
      '0x3D940e2Ee4430C1fF1c8D345A70ba82ED3823E48': 'Eduardo',
    },
    web3Config: new Web3GameService(),
    account: null,
    username: '',
    oniCoinBalance: '',
    currentGameDay: '',
    currentGameDayUnix: '',
    lastBlockTimestamp: '',
    firstPart: '',
    checkCondition: '',
    dayInSec: '',
    availableStoreItems: [],
    missionCounterResolved: '',
    lastDailyCheckIn: '',
    oniIdToLootBoxPositionToLootedAttrIdResolved: '',
    houseAttIdsInInventory: '',
    sideWindow: '',
    roofWindow: '',
    timesHaunted: '',
    firstFrontWindow: '',
    secondFrontWindow: '',
    towerWindow: '',
    chimney: '',
    door: '',
    roof: '',
    yard: '',
    lootBoxPositionClaimLoot: '',
    haunteeContractAddressGeneric: '',
    haunteeIDGeneric: '',
    haunteeContractHauntedSpecific: '',
    haunteeIDHauntedSpecific: '',
    slotPositionToBuyAttr: '',
    houseAttrIdToBuyAttr: '',
    ammountToBuyAttr: '',
    positionOpenLootBox: '',
    lootBoxPositionChangeLoot: '',
    hauntMessage: '',
    messages: [],
  }),
  methods: {
    getWeb3Config() {
      this.web3Config
        .getConfig()
        .then((config) => {
          this.account = config.accounts[0];
          this.username = this.walletToName[this.account];
          this.accountsWatch();
          this.getCurrentGameDay();
          this.getReceivedMessages();
          setTimeout((t) => {
            this.checkApprovedStore();
          }, 1000);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    accountsWatch() {
      if (this.web3Config.provider) {
        this.web3Config.provider.on('accountsChanged', (accounts) => {
          this.account = accounts[0];
          this.web3Config.account = accounts[0];
        });
      }
    },
    async updateHouseEquipedItems(nftAddressEquipedAttr, oniIDEquipedAttr) {
      let sideWindow = await this.web3Config.nftToHouseSlotToAttrID(nftAddressEquipedAttr, oniIDEquipedAttr, '1');
      let roofWindow = await this.web3Config.nftToHouseSlotToAttrID(nftAddressEquipedAttr, oniIDEquipedAttr, '2');
      let firstFrontWindow = await this.web3Config.nftToHouseSlotToAttrID(nftAddressEquipedAttr, oniIDEquipedAttr, '3');
      let secondFrontWindow = await this.web3Config.nftToHouseSlotToAttrID(
        nftAddressEquipedAttr,
        oniIDEquipedAttr,
        '4'
      );
      let towerWindow = await this.web3Config.nftToHouseSlotToAttrID(nftAddressEquipedAttr, oniIDEquipedAttr, '5');
      let door = await this.web3Config.nftToHouseSlotToAttrID(nftAddressEquipedAttr, oniIDEquipedAttr, '6');
      let chimney = await this.web3Config.nftToHouseSlotToAttrID(nftAddressEquipedAttr, oniIDEquipedAttr, '7');
      let roof = await this.web3Config.nftToHouseSlotToAttrID(nftAddressEquipedAttr, oniIDEquipedAttr, '8');
      let yard = await this.web3Config.nftToHouseSlotToAttrID(nftAddressEquipedAttr, oniIDEquipedAttr, '9');

      this.sideWindow = sideWindow;
      this.roofWindow = roofWindow;
      this.firstFrontWindow = firstFrontWindow;
      this.secondFrontWindow = secondFrontWindow;
      this.towerWindow = towerWindow;
      this.chimney = chimney;
      this.door = door;
      this.roof = roof;
      this.yard = yard;
    },
    async claimLootBoxItem(nftAddressClaimLoot, oniIDClaimLoot, lootBoxPositionClaimLoot) {
      await this.web3Config.claimLootBoxItem(nftAddressClaimLoot, oniIDClaimLoot, lootBoxPositionClaimLoot);
    },
    async changeLootboxAttr(nftAddressChangeLoot, oniIDChangeLoot, lootBoxPositionChangeLoot) {
      await this.web3Config.changeLootboxAttr(nftAddressChangeLoot, oniIDChangeLoot, lootBoxPositionChangeLoot);
    },
    async openLootBox(contractOpenLootBox, nftIDOpenLootBox) {
      await this.web3Config.openLootBox(contractOpenLootBox, nftIDOpenLootBox);
    },
    async nftIdToLootBoxPositionToLootedAttrId(nftContractAddress, contractOpenLootBox, nftIDOpenLootBox) {
      let oniIdToLootBoxPositionToLootedAttrIdResolved = await this.web3Config.nftIdToLootBoxPositionToLootedAttrId(
        nftContractAddress,
        contractOpenLootBox,
        nftIDOpenLootBox
      );
      console.log(oniIdToLootBoxPositionToLootedAttrIdResolved);
      this.oniIdToLootBoxPositionToLootedAttrIdResolved = oniIdToLootBoxPositionToLootedAttrIdResolved.toString();
    },
    async getOniCoinBalance(address) {
      let oniCoinBalance = await this.web3Config.getOniCoinBalance(address);
      this.oniCoinBalance = oniCoinBalance;
    },
    async doDailyCheckIn(nftAddress, nftID) {
      await this.web3Config.doDailyCheckIn(nftAddress, nftID);
      await this.getCurrentGameDay();
    },
    async getCurrentGameDay() {
      let currentGameDay = await this.web3Config.currentDayInt();
      this.currentGameDay = currentGameDay;
    },
    async getTimesHauntedNft(nftAddress, nftId) {
      let timesHaunted = await this.web3Config.getTimesHauntedNft(nftAddress, nftId);
      this.timesHaunted = timesHaunted;
    },
    async createHauntApiCall(nftContracthaunter, nftIDhaunter, nftContractHauntee, oniTobeHauntee, message) {
      const body = {
        haunter_contract_id: nftContracthaunter,
        haunter_id: nftIDhaunter,
        hauntee_contract_id: nftContractHauntee,
        hauntee_id: oniTobeHauntee,
        message: message,
      };
      axios.post(`${process.env.VUE_APP_SERVER_URI}/api/haunt/create`, body);
    },
    async hauntGenericOni(
      nftContractHauntedGeneric,
      nftIDHauntedGeneric,
      nftContractHaunteeGeneric,
      oniTobeHauntedGeneric,
      eventDay,
      message
    ) {
      console.log(nftContractHauntedGeneric);
      console.log(nftIDHauntedGeneric);
      console.log(nftContractHaunteeGeneric);
      console.log(oniTobeHauntedGeneric);
      console.log(eventDay);
      const response = await this.web3Config.hauntGenericOni(
        nftContractHauntedGeneric,
        nftIDHauntedGeneric,
        nftContractHaunteeGeneric,
        oniTobeHauntedGeneric,
        eventDay
      );
      if (response) {
        await this.createHauntApiCall(
          nftContractHauntedGeneric,
          nftIDHauntedGeneric,
          nftContractHaunteeGeneric,
          oniTobeHauntedGeneric,
          message
        );
      }
    },
    async hauntSpecificNFT(
      nftContractHauntedSpecific,
      nftIDHauntedSpecific,
      nftHaunteeContractAddress,
      oniTobeHauntedSpecific,
      eventDay,
      message
    ) {
      const response = await this.web3Config.hauntSpecificNFT(
        nftContractHauntedSpecific,
        nftIDHauntedSpecific,
        nftHaunteeContractAddress,
        oniTobeHauntedSpecific,
        eventDay
      );
      if (response) {
        await this.createHauntApiCall(
          nftContractHauntedSpecific,
          nftIDHauntedSpecific,
          nftHaunteeContractAddress,
          oniTobeHauntedSpecific,
          message
        );
      }
    },
    async claimDailyBonus(nftContractAddress, nftId) {
      await this.web3Config.claimDailyBonus(nftContractAddress, nftId);
    },
    async claimWeeklyBonus(nftContractAddress, nftId) {
      await this.web3Config.claimWeeklyBonus(nftContractAddress, nftId);
    },
    async buyLootBoxItem(nftAddress, nftID) {
      await this.web3Config.buyLootBoxItem(nftAddress, nftID);
    },
    async buyHouseAttrs(
      nftContractToBuyAttr,
      nftIDToBuyAttr,
      slotPositionToBuyAttr,
      houseAttrIdToBuyAttr,
      ammountToBuyAttr
    ) {
      await this.web3Config.buyHouseAttrs(
        nftContractToBuyAttr,
        nftIDToBuyAttr,
        slotPositionToBuyAttr,
        houseAttrIdToBuyAttr,
        ammountToBuyAttr
      );
    },
    async nftToHouseSlotToAttrID(nftAddressHouseAttr, oniIDHouseAttr, attrPositionHouseAttr) {
      let attrInPosition = await this.web3Config.nftToHouseSlotToAttrID(
        nftAddressHouseAttr,
        oniIDHouseAttr,
        attrPositionHouseAttr
      );
      this.attrInPosition = attrInPosition;
    },
    async isHouseAttrAvailableInStore(houseAttrId) {
      let availableItem = await this.web3Config.isHouseAttrAvailableInStore(houseAttrId);
      return !availableItem;
    },
    async getAllStoreAvailableItems() {
      this.availableStoreItems = [];
      let requestPrice = '';
      let itemSlotType = '';
      let randomId = '';
      console.log('Requested Price: ', requestPrice);
      for (var i = 1; i < 20; i++) {
        randomId = Math.floor(Math.random() * 50 + 1);
        requestPrice = await this.getAttrPrice(randomId);
        requestPrice = requestPrice == '0' ? '-1' : requestPrice;
        itemSlotType = await this.getAttrType(randomId);
        if (requestPrice != '-1' && itemSlotType.toString() != '0') {
          this.availableStoreItems.push({ id: randomId, price: requestPrice, slotType: itemSlotType });
        }
      }
    },
    async getHouseAttrs(nftcontractAddress, oniId) {
      let oniItems = await this.web3Config.getHouseAttrs(nftcontractAddress, oniId);
      this.houseAttIdsInInventory = oniItems;
    },
    async equipAttrToHouse(nftAddressEquipAttr, nftIDEquipAttr, attrIDEquipAttr, slopPositionIDEquipAttr) {
      await this.web3Config.equipAttrToHouse(
        nftAddressEquipAttr,
        nftIDEquipAttr,
        attrIDEquipAttr,
        slopPositionIDEquipAttr
      );
    },
    async getFirstPart() {
      let firstPart = await this.web3Config.getFirstPart();
      this.firstPart = firstPart;
    },
    async getCheckCondition() {
      let checkCondition = await this.web3Config.getCheckCondition();
      this.checkCondition = checkCondition;
    },
    async getLastBlockTimeStamp() {
      let lastBlockTimestamp = await this.web3Config.getLastBlockTimestamp();
      this.lastBlockTimestamp = lastBlockTimestamp;
    },
    async getCurrentGameDayUnix() {
      let currentGameDayUnix = await this.web3Config.getCurrentDayUnix();
      this.currentGameDayUnix = currentGameDayUnix;
    },
    async getLastTimeDailyCheckIn(nftAddress, nftID) {
      let lastDailyCheckIn = await this.web3Config.getLastDailyCheckIn(nftAddress, nftID);
      this.lastDailyCheckIn = lastDailyCheckIn;
    },
    async getCurrentDayInSec() {
      let dayInSec = await this.web3Config.getDayInSec();
      this.dayInSec = dayInSec;
    },
    async getAttrPrice(attrID) {
      let attrPriceResolved = await this.web3Config.getAttrPrice(attrID);
      return attrPriceResolved;
    },
    async getAttrType(attrID) {
      let attrTypeResolved = await this.web3Config.getAttrType(attrID);
      return attrTypeResolved;
    },
    async approveToStore() {
      await this.web3Config.approveToStore();
    },
    async missionCounter(contractMissionCounter, nftIDOMissionCounter) {
      let missionCounterResolved = await this.web3Config.missionCounter(contractMissionCounter, nftIDOMissionCounter);
      this.missionCounterResolved = missionCounterResolved;
    },
    async getReceivedMessages() {
      const params = {
        hauntee_id: this.nftID,
        hauntee_contract_id: this.nftAddress,
      };
      console.log(params);
      const response = await axios.get(`${process.env.VUE_APP_SERVER_URI}/api/haunt/get-hauntee-messages`, { params });
      this.messages = response.data;
    },

    async checkApprovedStore() {
      const result = this.web3Config.checkApprovedToStore();
      console.log(result);
    },
  },
  mounted() {
    const { nftAddress, nftID } = this.$route.params;
    this.nftID = nftID;
    this.nftAddress = nftAddress;
    this.getWeb3Config();
  },
};
